'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface FlipWordsProps {
  words: string[];
  className?: string;
  initialWord?: string;
  duration?: number;
}

export const FlipWords: React.FC<FlipWordsProps> = ({
  words,
  className,
  initialWord,
  duration = 4000,
}) => {
  const [index, setIndex] = useState(0);
  const [displayWord, setDisplayWord] = useState(initialWord || words[0]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  // Optimisation du premier rendu
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
  }, [isFirstRender]);

  const startAnimation = useCallback(() => {
    if (isFirstRender) return;
    const word = words[words.indexOf(displayWord) + 1] || words[0];
    setDisplayWord(word);
    setIndex(words.indexOf(word));
  }, [displayWord, words, isFirstRender]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (index === 0 && !isFirstRender) {
      timeoutId = setTimeout(() => {
        startAnimation();
      }, duration);
    }
    return () => clearTimeout(timeoutId);
  }, [index, startAnimation, duration, isFirstRender]);

  const handleExitComplete = useCallback(() => {
    setIndex(0);
  }, []);

  const combinedClassName = useMemo(
    () =>
      cn(
        'z-10 inline-block relative text-left text-neutral-900 dark:text-neutral-100 px-2',
        className,
      ),
    [className],
  );

  const motionDivInitial = useMemo(
    () => ({
      opacity: isFirstRender ? 1 : 0,
      y: isFirstRender ? 0 : 10,
    }),
    [isFirstRender],
  );

  const motionDivAnimate = useMemo(
    () => ({
      opacity: 1,
      y: 0,
    }),
    [],
  );

  const motionDivTransition = useMemo(
    () => ({
      type: 'spring',
      stiffness: 100,
      damping: 10,
    }),
    [],
  );

  const motionDivExit = useMemo(
    () => ({
      opacity: 0,
      y: -40,
      x: 40,
      filter: 'blur(8px)',
      scale: 2,
      position: 'absolute' as const,
    }),
    [],
  );

  // Déplacer les memos en dehors des callbacks
  const createWordAnimationProps = (wordIndex: number) => {
    return {
      initial: {
        opacity: 0,
        y: 10,
        filter: 'blur(8px)',
      },
      animate: {
        opacity: 1,
        y: 0,
        filter: 'blur(0px)',
      },
      transition: {
        delay: wordIndex * 0.3,
        duration: 0.3,
      },
    };
  };

  const createLetterAnimationProps = (
    wordIndex: number,
    letterIndex: number,
  ) => {
    return {
      initial: {
        opacity: 0,
        y: 10,
        filter: 'blur(8px)',
      },
      animate: {
        opacity: 1,
        y: 0,
        filter: 'blur(0px)',
      },
      transition: {
        delay: wordIndex * 0.3 + letterIndex * 0.05,
        duration: 0.2,
      },
    };
  };

  return (
    <AnimatePresence onExitComplete={handleExitComplete}>
      <motion.div
        initial={motionDivInitial}
        animate={motionDivAnimate}
        transition={motionDivTransition}
        exit={motionDivExit}
        className={combinedClassName}
        key={displayWord}
      >
        {displayWord.split(' ').map((word, wordIndex) => {
          const animationProps = createWordAnimationProps(wordIndex);

          return (
            <motion.span
              key={word + wordIndex}
              initial={animationProps.initial}
              animate={animationProps.animate}
              transition={animationProps.transition}
              className="inline-block whitespace-nowrap"
            >
              {word.split('').map((letter, letterIndex) => {
                const letterAnimationProps = createLetterAnimationProps(
                  wordIndex,
                  letterIndex,
                );

                return (
                  <motion.span
                    key={word + letterIndex}
                    initial={letterAnimationProps.initial}
                    animate={letterAnimationProps.animate}
                    transition={letterAnimationProps.transition}
                    className="inline-block"
                  >
                    {letter}
                  </motion.span>
                );
              })}
              <span className="inline-block">&nbsp;</span>
            </motion.span>
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
};
