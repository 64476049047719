'use client';

import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

export function ServiceSkeleton() {
  return (
    <figure
      className={cn(
        'relative mx-auto min-h-fit w-full max-w-[400px] overflow-hidden rounded-xl p-2 sm:p-4',
        'transition-all duration-300 ease-in-out',
        'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]',
        'transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]',
      )}
    >
      <div className="flex flex-row items-center gap-2 sm:gap-3">
        <Skeleton className="relative h-8 w-8 shrink-0 rounded-lg opacity-80 sm:h-12 sm:w-12 sm:rounded-xl" />
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-row items-center gap-1.5">
            <Skeleton className="h-4 w-32 sm:h-5 md:h-6" />
            <Skeleton className="h-3 w-12 sm:h-4" />
          </div>
          <Skeleton className="mt-1 h-3 w-full sm:h-4" />
          <Skeleton className="mt-0.5 h-3 w-[80%] sm:h-4" />
        </div>
      </div>
    </figure>
  );
}
