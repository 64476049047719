'use client';

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface AnimatedListProps {
  className?: string;
  children: React.ReactNode;
  delay?: number;
}

export const AnimatedList = React.memo(
  ({ className, children, delay = 1000 }: AnimatedListProps) => {
    const [index, setIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const childrenArray = React.Children.toArray(children);

    useEffect(() => {
      if (isPaused) return;

      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % childrenArray.length);
      }, delay);

      return () => clearInterval(interval);
    }, [childrenArray.length, delay, isPaused]);

    const itemsToShow = useMemo(
      () => childrenArray.slice(0, index + 1).reverse(),
      [index, childrenArray],
    );

    const animatedItems = useMemo(
      () => (
        <AnimatePresence>
          {itemsToShow.map((item) => (
            <AnimatedListItem key={(item as ReactElement).key}>
              {item}
            </AnimatedListItem>
          ))}
        </AnimatePresence>
      ),
      [itemsToShow],
    );

    return (
      <div
        className={`flex flex-col items-center gap-4 ${className}`}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {animatedItems}
      </div>
    );
  },
);

AnimatedList.displayName = 'AnimatedList';

const AnimatedListItem = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const animations = useMemo(
      () => ({
        initial: { scale: 0, opacity: 0 },
        animate: { scale: 1, opacity: 1, originY: 0 },
        exit: { scale: 0, opacity: 0 },
        transition: { type: 'spring', stiffness: 350, damping: 40 },
      }),
      [],
    );

    return (
      <motion.div
        initial={animations.initial}
        animate={animations.animate}
        exit={animations.exit}
        transition={animations.transition}
        layout
        className="mx-auto w-full"
      >
        {useMemo(() => children, [children])}
      </motion.div>
    );
  },
);

AnimatedListItem.displayName = 'AnimatedListItem';

export { AnimatedListItem };
