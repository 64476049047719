'use client';

import { useEffect, useState, memo } from 'react';
import { cn } from '@/lib/utils';
import Marquee from '@/components/ui/magicui/marquee';
import Image from 'next/image';
import Link from 'next/link';
import { PersonIcon } from '@radix-ui/react-icons';
import { Review, Project } from '@/types/project';
import AnimatedShinyText from '@/components/ui/magicui/animated-shiny-text';
import { ReviewSkeleton } from './ReviewSkeleton';

interface ReviewCardProps extends Omit<Review, 'id' | 'projectTitle'> {
  hasImage: boolean;
  imgProfile?: string;
}

const ReviewCard = memo(
  ({
    imgProfile,
    hasImage,
    author,
    date,
    review,
    company,
  }: ReviewCardProps) => {
    return (
      <figure
        className={cn(
          'relative w-[400px] overflow-hidden rounded-xl border p-4',
          'bg-white hover:bg-gray-50',
          'transform-gpu transition-all duration-300',
          'dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-10px_40px_-10px_#ffffff1f_inset]',
          'hover:dark:[box-shadow:0_-10px_40px_-10px_#ffffff3f_inset]',
        )}
      >
        <div className="flex flex-row items-center gap-2">
          {hasImage ? (
            <Image
              className="rounded-full"
              draggable="false"
              width="32"
              height="32"
              alt={author}
              src={imgProfile!}
              loading="lazy"
            />
          ) : (
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800">
              <PersonIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            </div>
          )}
          <div className="flex flex-col">
            <figcaption className="text-sm font-medium">{author}</figcaption>
            <div className="flex flex-col">
              <p className="text-xs font-medium text-muted-foreground">
                {date}
              </p>
              <p className="text-xs font-medium">{company}</p>
            </div>
          </div>
        </div>
        <blockquote className="mt-2 text-sm line-clamp-3 text-muted-foreground">
          {review}
        </blockquote>
      </figure>
    );
  },
);

ReviewCard.displayName = 'ReviewCard';

export function Reviews() {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        const projectsData = await import('@/public/data/projects.json');
        const allReviews = (projectsData.default as Project[])
          .filter((project) => project.reviews && project.reviews.length > 0)
          .flatMap((project) => project.reviews as Review[]);

        setReviews(allReviews);
      } catch (error) {
        console.error('Erreur lors du chargement des avis:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadReviews();
  }, []);

  const firstRow = reviews.slice(0, Math.ceil(reviews.length / 2));
  const secondRow = reviews.slice(Math.ceil(reviews.length / 2));

  return (
    <section className="container py-16 md:py-24" id="reviews">
      <AnimatedShinyText className="text-sm font-light">
        NOS AVIS
      </AnimatedShinyText>
      <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background pt-8">
        <Link href="/avis" className="flex flex-col gap-4">
          {isLoading ? (
            <>
              <Marquee className="[--duration:40s]">
                {[...Array(3)].map((_, i) => (
                  <ReviewSkeleton key={i} />
                ))}
              </Marquee>
              <Marquee reverse className="[--duration:40s]">
                {[...Array(3)].map((_, i) => (
                  <ReviewSkeleton key={i + 3} />
                ))}
              </Marquee>
            </>
          ) : (
            <>
              <Marquee pauseOnHover className="[--duration:40s]">
                {firstRow.map((review) => (
                  <ReviewCard
                    key={review.id}
                    {...review}
                    hasImage={!!review.imgProfile}
                  />
                ))}
              </Marquee>
              <Marquee reverse pauseOnHover className="[--duration:40s]">
                {secondRow.map((review) => (
                  <ReviewCard
                    key={review.id}
                    {...review}
                    hasImage={!!review.imgProfile}
                  />
                ))}
              </Marquee>
            </>
          )}
          <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-background" />
          <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-background" />
        </Link>
      </div>
    </section>
  );
}
