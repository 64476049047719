'use client';
import React from 'react';
import { AnimatedTooltip } from '@/components/ui/aceternity/animated-tooltip';
import projectsData from '@/public/data/projects.json';
import { Project, Review, TooltipItem } from '@/types/project';
import Lottie from 'lottie-react';
import fiveStarsAnimation from '@/lottie/five-stars.json';
import Link from 'next/link';

interface ExtendedTooltipItem extends TooltipItem {
  hasCustomImage: boolean;
}

// Optimisation de prepareReviews avec useMemo
const prepareReviews = () => {
  const allReviews = (projectsData as Project[]).reduce<ExtendedTooltipItem[]>(
    (acc, project) => {
      if (!project.reviews?.length) return acc;

      return [
        ...acc,
        ...project.reviews.map((review: Review) => ({
          id: review.id,
          name: review.author,
          designation: review.company,
          image: review.imgProfile || '/images/default-user.webp',
          hasCustomImage: !!review.imgProfile,
        })),
      ];
    },
    [],
  );

  const shuffleArray = <T extends TooltipItem>(array: T[]): T[] =>
    [...array].sort(() => Math.random() - 0.5);

  const { withImages, withoutImages } = allReviews.reduce(
    (acc, review) => {
      acc[review.hasCustomImage ? 'withImages' : 'withoutImages'].push(review);
      return acc;
    },
    {
      withImages: [] as ExtendedTooltipItem[],
      withoutImages: [] as ExtendedTooltipItem[],
    },
  );

  const selectedReviews = [
    ...shuffleArray(withImages).slice(0, 6),
    ...shuffleArray(withoutImages).slice(0, Math.max(0, 6 - withImages.length)),
  ];

  return shuffleArray(selectedReviews);
};

export function Reviews() {
  const reviews = React.useMemo(() => prepareReviews(), []);

  return (
    <Link href="/avis">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-center gap-2 mb-2 w-full">
          <AnimatedTooltip items={reviews} />
        </div>
        <div className="flex flex-col items-center">
          <div className="size-28 -mb-20 -mt-2">
            <Lottie
              animationData={fiveStarsAnimation}
              loop={true}
              autoplay={true}
            />
          </div>
          <p className="text-sm text-muted-foreground">
            Rejoignez nos clients satisfaits !
          </p>
        </div>
      </div>
    </Link>
  );
}
