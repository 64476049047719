'use client';

import { cn } from '@/lib/utils';

export const BackgroundGradient = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'group relative overflow-hidden rounded-xl border dark:border-white/[0.2]',
        className,
      )}
    >
      <div className="pointer-events-none absolute inset-0 z-10 h-full transform-gpu overflow-hidden">
        <div
          className="absolute -left-[40%] -top-[40%] h-[80%] w-[80%] rounded-full bg-gradient-to-r from-primary/20 to-primary/40 opacity-0 blur-3xl transition-opacity duration-500 group-hover:opacity-100"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      {children}
    </div>
  );
};
