'use client';

import React, { Suspense } from 'react';
import { useEffect, useRef } from 'react';
import { useTheme } from 'next-themes';
import { BackgroundGradient } from '@/components/ui/aceternity/background-gradient';

// Composant de fallback pendant le chargement
const GlobeFallback = ({ className }: { className?: string }) => (
  <div
    className={`relative w-[600px] h-[600px] max-w-full aspect-square ${className}`}
  >
    <BackgroundGradient className="rounded-full opacity-40">
      <div className="w-full h-full rounded-full" />
    </BackgroundGradient>
  </div>
);

// Type pour l'état du rendu tel qu'attendu par cobe
type RenderState = Record<string, number>;

// Type pour le renderer
interface Renderer {
  destroy: () => void;
}

function GlobeCanvas({ className }: { className?: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { theme } = useTheme();

  useEffect(() => {
    let mounted = true;
    let phi = 0;
    let globeInstance: Renderer | null = null;

    const initGlobe = async () => {
      if (!mounted || !canvasRef.current) return;

      try {
        const createGlobe = (await import('cobe')).default;

        globeInstance = createGlobe(canvasRef.current, {
          devicePixelRatio: 2,
          width: 600 * 2,
          height: 600 * 2,
          phi: 0,
          theta: 0,
          dark: theme === 'dark' ? 1 : 0,
          diffuse: 1.2,
          mapSamples: 16000,
          mapBrightness: 6,
          baseColor: theme === 'dark' ? [0.1, 0.1, 0.1] : [0.9, 0.9, 0.9],
          markerColor: [0.4, 0.2, 0.8],
          glowColor: theme === 'dark' ? [0.2, 0.1, 0.4] : [0.4, 0.3, 0.7],
          markers: [{ location: [48.64357, 2.3254], size: 0.05 }],
          onRender: (state: RenderState) => {
            if (!mounted) return;
            state.phi = phi;
            phi += 0.01;
          },
        });
      } catch (error) {
        console.error('Erreur lors du chargement du globe:', error);
      }
    };

    initGlobe();

    return () => {
      mounted = false;
      if (globeInstance) {
        globeInstance.destroy();
      }
    };
  }, [theme]);

  return (
    <canvas
      ref={canvasRef}
      style={{ width: 600, height: 600, maxWidth: '100%', aspectRatio: 1 }}
      className={className}
    />
  );
}

// Composant principal avec Suspense
export default function Globe({ className }: { className?: string }) {
  return (
    <Suspense fallback={<GlobeFallback className={className} />}>
      <GlobeCanvas className={className} />
    </Suspense>
  );
}
