import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

export function ReviewSkeleton() {
  return (
    <div
      className={cn(
        'relative w-[400px] overflow-hidden rounded-xl border p-4',
        'bg-white',
        'dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)]',
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <Skeleton className="h-8 w-8 rounded-full" />
        <div className="flex flex-col gap-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-3 w-20" />
          <Skeleton className="h-3 w-32" />
        </div>
      </div>
      <div className="mt-2 space-y-2">
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-[80%]" />
      </div>
    </div>
  );
}
