import React from 'react';
import type { ReactNode } from 'react';
import {
  PhoneCallIcon,
  PencilRulerIcon,
  CodeIcon,
  RocketIcon,
  HeartHandshakeIcon,
} from 'lucide-react';

export interface Step {
  title: string;
  description: string;
  icon: ReactNode;
}

export const steps: Step[] = [
  {
    title: 'Consultation Gratuite',
    description:
      'Premier appel découverte pour comprendre vos besoins et objectifs. Nous échangeons sur votre vision et posons ensemble les fondations de votre projet web.',
    icon: <PhoneCallIcon className="h-6 w-6" />,
  },
  {
    title: 'Design & Architecture',
    description:
      "Création d'une maquette détaillée et définition de l'architecture de votre site web. Nous structurons l'organisation des contenus pour une expérience utilisateur optimale.",
    icon: <PencilRulerIcon className="h-6 w-6" />,
  },
  {
    title: 'Développement',
    description:
      'Développement de votre site avec les technologies les plus modernes. Nous effectuons des tests approfondis pour garantir une qualité et des performances optimales.',
    icon: <CodeIcon className="h-6 w-6" />,
  },
  {
    title: 'Mise en Ligne',
    description:
      "Déploiement professionnel de votre site web et optimisations finales pour des performances maximales. Nous vous formons à l'utilisation de votre nouvelle plateforme.",
    icon: <RocketIcon className="h-6 w-6" />,
  },
  {
    title: 'Suivi & Support',
    description:
      'Accompagnement personnalisé et support technique réactif après le lancement. Nous assurons les mises à jour et implémentations selon vos besoins.',
    icon: <HeartHandshakeIcon className="h-6 w-6" />,
  },
];
