'use client';

import Marquee from '@/components/ui/magicui/marquee';
import AnimatedShinyText from '@/components/ui/magicui/animated-shiny-text';
import Image from 'next/image';
import { LampContainer } from '@/components/ui/aceternity/lamp';

const brands = [
  {
    id: 1,
    name: 'Vercel',
    path: '/images/brands/Vercel.svg',
  },
  {
    id: 3,
    name: 'Google',
    path: '/images/brands/Google.svg',
  },
  {
    id: 4,
    name: 'Microsoft',
    path: '/images/brands/Microsoft.svg',
  },
  {
    id: 5,
    name: 'GitHub',
    path: '/images/brands/GitHub.svg',
  },
  {
    id: 6,
    name: 'Next.js',
    path: '/images/brands/NextJs.svg',
  },
  {
    id: 7,
    name: 'OpenAI',
    path: '/images/brands/OpenAI.svg',
  },
  {
    id: 8,
    name: 'AWS',
    path: '/images/brands/AWS.svg',
  },
];

const BrandLogo = ({ name, path }: { name: string; path: string }) => {
  return (
    <div className="mx-8 h-12 w-32 relative">
      <Image
        src={path}
        alt={`${name} logo`}
        fill
        className="object-contain dark:invert"
        loading="lazy"
        sizes="(max-width: 640px) 100vw, (max-width: 1920px) 50vw, 33vw"
        priority={false}
      />
    </div>
  );
};

export function Brands() {
  return (
    <LampContainer>
      <div className="relative container py-16 md:py-24">
        <AnimatedShinyText className="flex justify-center mb-8">
          Des outils leaders sur le marché
        </AnimatedShinyText>
        <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">
          <div className="flex flex-col">
            <Marquee pauseOnHover={true} className="[--duration:30s]">
              {brands.map((brand) => (
                <BrandLogo key={brand.id} name={brand.name} path={brand.path} />
              ))}
            </Marquee>
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-background"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-background"></div>
          </div>
        </div>
      </div>
    </LampContainer>
  );
}
