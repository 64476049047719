'use client';

import React from 'react';
import {
  ChatBubbleIcon,
  StopwatchIcon,
  RocketIcon,
  HomeIcon,
  MobileIcon,
} from '@radix-ui/react-icons';
import { FiAward } from 'react-icons/fi';
import { FaRegCreditCard } from 'react-icons/fa6';
import AnimatedShinyText from '@/components/ui/magicui/animated-shiny-text';
import { CallToAction } from '@/components/common/CallToAction';
//import Image from 'next/image';
import Globe from './Globe';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '@/components/ui/card';
import { motion, useInView } from 'framer-motion';
import NumberTicker from '@/components/ui/magicui/number-ticker';
//import { PhoneCall } from 'lucide-react';

export default function About() {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {
    once: true,
    amount: 0.1,
    margin: '0px 0px -100px 0px',
  });

  const features = [
    {
      icon: <HomeIcon className="size-6" />,
      title: 'Hébergement gratuit',
      description:
        'Hébergement gratuit pour les sites à faible trafic, vous permettant de démarrer sans frais supplémentaires.',
    },
    {
      icon: <FaRegCreditCard className="size-6" />,
      title: 'Paiement flexible',
      description:
        "Possibilité de paiement en plusieurs fois pour s'adapter à votre budget et faciliter votre investissement.",
    },
    {
      icon: <StopwatchIcon className="size-6" />,
      title: 'Réactivité',
      description:
        'Nous assurons une réponse rapide à chaque étape pour une meilleure gestion de vos projets.',
    },
    {
      icon: <MobileIcon className="size-6" />,
      title: 'Mobile First',
      description:
        'Sites web optimisés pour une expérience mobile parfaite, adaptés aux usages actuels.',
    },
    {
      icon: <ChatBubbleIcon className="size-6" />,
      title: 'Accompagnement',
      description:
        'Nous vous accompagnons tout au long du processus avec une communication transparente.',
    },
    {
      icon: <RocketIcon className="size-6" />,
      title: 'Performance',
      description:
        'Optimisation de la performance pour garantir des applications rapides et fluides.',
    },
    {
      icon: <FiAward className="size-6" />,
      title: 'Engagement et qualité',
      description:
        'Un engagement fort pour fournir une qualité irréprochable à chaque projet.',
    },
  ];

  const metrics = [
    { label: 'Performance', score: 100 },
    { label: 'Accessibilité', score: 100 },
    { label: 'Bonnes pratiques', score: 100 },
    { label: 'SEO', score: 100 },
  ];

  return (
    <section className="container" id="about">
      <AnimatedShinyText className="text-sm font-light">
        À PROPOS
      </AnimatedShinyText>
      <div className="flex flex-col gap-8 pt-8">
        <div className="grid gap-8 md:grid-cols-2">
          <div className="flex flex-col justify-between">
            <div>
              <h4 className="mb-2 text-2xl font-bold">
                Créez votre site web 100% sur-mesure qui attire et convertit
              </h4>
              <p className="mb-6 text-muted-foreground">
                Chaque projet est une opportunité de propulser votre entreprise
                vers de nouveaux sommets grâce à des sites web rapides,
                performants et adaptés à vos objectifs.
              </p>
            </div>

            <div className="mb-6 grid gap-4">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="p-1.5 border rounded-full">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold">{feature.title}</h3>
                    <p className="text-sm text-muted-foreground max-w-md">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex">
              <CallToAction
                text="Obtenez un devis gratuit"
                fullWidth
                href="/contact"
              />
            </div>
          </div>

          <div className="flex flex-col gap-8 justify-between">
            <Card className="overflow-hidden h-fit relative mb-8">
              <CardHeader>
                <CardTitle className="z-10">
                  Accessible, fiable et visible partout dans le monde
                </CardTitle>
                <CardDescription className="md:max-w-sm relative z-10">
                  Un site web disponible 24h/24 et 7j/7, pour capter des clients
                  à tout moment, où qu&apos;ils se trouvent.
                </CardDescription>
              </CardHeader>
              <CardContent className="relative" ref={ref}>
                <Globe className="absolute bottom-[-200px] right-[-100px] sm:right-[-200px] scale-75 md:scale-100 blur-sm md:bottom-[-350px] md:right-[-260px] md:blur-none transition-all duration-300" />
                <div className="md:max-w-sm relative z-10 space-y-2 text-sm text-muted-foreground">
                  <p>
                    Attirez de nouveaux clients en offrant une expérience en
                    ligne fluide et accessible, partout et tout le temps.
                  </p>
                  <p>
                    Que vous visiez une clientèle locale ou internationale, nous
                    optimisons votre site pour être performant, sécurisé et
                    toujours en ligne.
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Performance et qualité optimales</CardTitle>
                <CardDescription>
                  Nos sites web sont optimisés selon les standards Google
                  Lighthouse, garantissant une expérience utilisateur
                  exceptionnelle et un référencement optimal.
                </CardDescription>
              </CardHeader>
              <CardContent className="relative" ref={ref}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                  {metrics.map((metric, index) => (
                    <div
                      key={metric.label}
                      className="flex flex-col items-center gap-2"
                    >
                      <div className="relative">
                        <svg className="w-16 h-16 -rotate-90">
                          <circle
                            className="text-muted stroke-current"
                            strokeWidth="5"
                            fill="transparent"
                            r="28"
                            cx="32"
                            cy="32"
                          />
                          <motion.circle
                            className="text-green-500 stroke-current origin-center"
                            strokeWidth="5"
                            strokeLinecap="round"
                            fill="transparent"
                            r="28"
                            cx="32"
                            cy="32"
                            initial={{ pathLength: 0 }}
                            animate={
                              isInView ? { pathLength: 1 } : { pathLength: 0 }
                            }
                            transition={{
                              duration: 1.5,
                              delay: index * 0.2,
                              ease: 'easeOut',
                            }}
                            key={isInView ? 'animate' : 'reset'}
                          />
                        </svg>
                        <div className="absolute inset-0 flex items-center justify-center">
                          {isInView && (
                            <NumberTicker
                              value={metric.score}
                              className="text-sm font-bold"
                              delay={index * 0.3}
                            />
                          )}
                        </div>
                      </div>
                      <span className="text-sm font-medium text-center">
                        {metric.label}
                      </span>
                    </div>
                  ))}
                </div>

                <div className="mt-6 space-y-4 text-sm text-muted-foreground">
                  <p>
                    Nous visons l&apos;excellence avec des scores Google
                    Lighthouse optimaux grâce à :
                  </p>
                  <ul className="list-disc pl-4 space-y-2">
                    <li>
                      Une architecture moderne optimisée pour des chargements
                      rapides
                    </li>
                    <li>
                      Des pratiques d&apos;accessibilité suivant les normes WCAG
                    </li>
                    <li>
                      Un code propre respectant les standards du développement
                      web
                    </li>
                    <li>
                      Une optimisation SEO approfondie pour un meilleur
                      référencement
                    </li>
                  </ul>
                  <p className="font-medium text-foreground">
                    Ces performances exceptionnelles se traduisent par une
                    meilleure expérience utilisateur et un meilleur classement
                    dans les résultats de recherche.
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}
