'use client';

import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import AnimatedShinyText from '@/components/ui/magicui/animated-shiny-text';

const faqItems = [
  {
    question: "Comment se déroule la création d'un site web ?",
    answer:
      "La création d'un site web avec eDeveloppe commence par un appel gratuit et sans engagement, où nous discutons en profondeur de vos besoins. Ensemble, nous définissons vos objectifs, les éléments de design, et les fonctionnalités spécifiques. Suite à cette phase de planification, nous vous proposons un devis clair et détaillé pour concrétiser votre projet avec professionnalisme.",
  },

  {
    question: 'Quels types de sites web développez-vous ?',
    answer:
      'Nous développons des sites web professionnels sur mesure : sites vitrines, sites corporate, blogs, applications web personnalisées. Chaque projet est créé avec des technologies modernes pour garantir performance, sécurité et évolutivité.',
  },
  {
    question: 'Comment optimisez-vous le référencement (SEO) des sites ?',
    answer:
      "L'optimisation SEO est intégrée dès la conception avec Next.js qui permet un excellent référencement naturel. Cela inclut : performance optimale, structure HTML sémantique, meta-descriptions personnalisées, responsive design et temps de chargement rapide.",
  },
  {
    question: 'Proposez-vous un hébergement pour les sites web ?',
    answer:
      "Oui, l'hébergement est inclus via Vercel pour les sites à faible trafic. Cette solution offre d'excellentes performances, une haute disponibilité et une sécurité optimale. Pour les projets plus importants, nous pouvons discuter d'autres solutions d'hébergement adaptées à vos besoins.",
  },
  {
    question: 'Quelles technologies utilisez-vous pour le développement ?',
    answer:
      'Nous utilisons une stack technique moderne et performante : TypeScript, Next.js, React, et Tailwind CSS pour le front-end. Pour le back-end, selon les besoins : Node.js, PostgreSQL, MongoDB. Cette combinaison assure des sites rapides, sécurisés et facilement maintenables.',
  },
  {
    question: 'Comment assurez-vous la performance des sites web ?',
    answer:
      "La performance est optimisée à plusieurs niveaux : utilisation de Next.js pour le rendu côté serveur, optimisation des images, mise en cache intelligente, code optimisé, et choix d'hébergement performant. Chaque site est testé pour garantir des temps de chargement rapides.",
  },
  {
    question: 'Les sites sont-ils adaptés aux mobiles ?',
    answer:
      "Absolument ! Tous les sites sont développés avec une approche 'mobile-first' et sont parfaitement responsives. Ils s'adaptent automatiquement à tous les appareils (smartphones, tablettes, ordinateurs) pour une expérience utilisateur optimale.",
  },
  {
    question: 'Proposez-vous un support après la mise en ligne ?',
    answer:
      'Oui, nous restons disponibles après le lancement pour vous accompagner, effectuer les mises à jour nécessaires et apporter des modifications selon vos besoins. Un suivi régulier est assuré pour garantir le bon fonctionnement de votre site.',
  },
  {
    question: "Combien coûte la création d'un site web ?",
    answer:
      'Le coût varie selon vos besoins spécifiques et la complexité du projet. Nous proposons un appel gratuit pour discuter de votre projet et vous fournir un devis précis. Chaque projet est unique et le prix reflète le travail sur mesure réalisé.',
  },
  {
    question: 'Quels sont les délais de réalisation ?',
    answer:
      "Les délais dépendent de la complexité du projet. Un site vitrine peut être réalisé en quelques semaines, tandis qu'une application web plus complexe nécessitera plus de temps. Un planning détaillé est établi lors de notre premier échange.",
  },
];

export function FAQ() {
  const midPoint = Math.ceil(faqItems.length / 2);
  const leftColumnItems = faqItems.slice(0, midPoint);
  const rightColumnItems = faqItems.slice(midPoint);

  return (
    <section className="container pb-16 md:pb-24" id="faq">
      <div>
        <AnimatedShinyText className="text-sm font-light">
          FAQ
        </AnimatedShinyText>

        <div className="grid md:grid-cols-2 md:gap-8 pt-8">
          <Accordion type="single" collapsible className="w-full">
            {leftColumnItems.map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger>{item.question}</AccordionTrigger>
                <AccordionContent className="text-muted-foreground">
                  {item.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>

          <Accordion type="single" collapsible className="w-full">
            {rightColumnItems.map((item, index) => (
              <AccordionItem
                key={index + midPoint}
                value={`item-${index + midPoint}`}
              >
                <AccordionTrigger>{item.question}</AccordionTrigger>
                <AccordionContent className="text-muted-foreground">
                  {item.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}
