'use client';

import React, { useMemo } from 'react';
import { cn } from '@/lib/utils';
import { steps } from '@/lib/data/steps';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

interface MethodStep {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const MethodCard = ({ step, index }: { step: MethodStep; index: number }) => {
  return (
    <div className="relative">
      <div
        className={cn(
          'group relative overflow-hidden rounded-xl border h-full',
          'bg-white hover:bg-gray-50',
          'transform-gpu transition-all duration-300',
          'dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-10px_40px_-10px_#ffffff1f_inset]',
          'hover:dark:[box-shadow:0_-10px_40px_-10px_#ffffff3f_inset]',
          'p-6',
        )}
      >
        <div className="flex w-full flex-col items-start">
          <div className="mb-2 flex items-center gap-2">
            <div className="rounded-full bg-background p-2 border">
              {step.icon}
            </div>
            <span className="text-sm text-muted-foreground">
              Étape {index + 1}
            </span>
          </div>
          <h3 className="text-xl font-semibold text-left whitespace-nowrap mb-4">
            {step.title}
          </h3>
          <p className="text-muted-foreground">{step.description}</p>
        </div>
      </div>
    </div>
  );
};

export function Method() {
  const memoizedSteps = useMemo(() => steps, []);

  return (
    <Carousel
      opts={{
        align: 'start',
        loop: true,
      }}
      className="w-full max-w-[800px] mx-auto pt-6"
    >
      <CarouselContent>
        {memoizedSteps.map((step, index) => (
          <CarouselItem key={step.title}>
            <MethodCard step={step} index={index} />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
}
