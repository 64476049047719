/* eslint-disable @typescript-eslint/no-unused-vars */

'use client';
import React, { useState, useCallback, useMemo } from 'react';
import {
  motion,
  useTransform,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from 'framer-motion';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

export const AnimatedTooltip = React.memo(
  ({
    items,
  }: {
    items: {
      id: number;
      name: string;
      designation: string;
      image: string;
    }[];
  }) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const springConfig = useMemo(() => ({ stiffness: 100, damping: 5 }), []);
    const x = useMotionValue(0); // going to set this value on mouse move
    // rotate the tooltip
    const rotate = useSpring(
      useTransform(x, [-100, 100], [-45, 45]),
      springConfig,
    );
    // translate the tooltip
    const translateX = useSpring(
      useTransform(x, [-100, 100], [-50, 50]),
      springConfig,
    );
    const handleMouseMove = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event: any) => {
        const halfWidth = event.target.offsetWidth / 2;
        x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
      },
      [x],
    );

    return (
      <>
        {items.map((item) => (
          <div
            className="-mr-4 relative group"
            key={item.name}
            onMouseEnter={() => setHoveredIndex(item.id)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <AnimatePresence mode="popLayout">
              {hoveredIndex === item.id && (
                <motion.div
                  initial={{ opacity: 0, y: 20, scale: 0.6 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    transition: {
                      type: 'spring',
                      stiffness: 260,
                      damping: 10,
                    },
                  }}
                  exit={{ opacity: 0, y: 20, scale: 0.6 }}
                  style={{
                    translateX: translateX,
                    rotate: rotate,
                    whiteSpace: 'nowrap',
                  }}
                  className="absolute -top-16 -left-1/2 translate-x-1/2 flex text-xs  flex-col items-center justify-center rounded-md bg-black z-50 shadow-xl px-4 py-2"
                >
                  <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-fuchsia-500 to-transparent h-px " />
                  <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-purple-500 to-transparent h-px " />
                  <div className="font-bold text-white relative z-30 text-base">
                    {item.name}
                  </div>
                  <div className="text-white text-xs">{item.designation}</div>
                </motion.div>
              )}
            </AnimatePresence>
            <Avatar
              onMouseMove={handleMouseMove}
              className="!m-0 !p-0 size-12 group-hover:scale-105 group-hover:z-30 relative transition duration-500 -rotate-12 group-hover:rotate-0"
            >
              <AvatarImage
                src={item.image}
                alt={item.name}
                className="object-cover object-top"
              />
              <AvatarFallback>ED</AvatarFallback>
            </Avatar>
          </div>
        ))}
      </>
    );
  },
);

AnimatedTooltip.displayName = 'AnimatedTooltip';
