'use client';
import React, { useMemo } from 'react';
import { Avatar } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import { PersonIcon } from '@radix-ui/react-icons';
import { Separator } from '@/components/ui/separator';
import Marquee from '@/components/ui/magicui/marquee';

interface Message {
  id: number;
  content: string;
  sender: 'client1' | 'client2' | 'me';
  timestamp: string;
}

interface ChatSimulationProps {
  className?: string;
}

const ChatSimulation = ({ className }: ChatSimulationProps) => {
  const RenderAvatarComponent = useMemo(() => {
    const AvatarRenderer = ({
      sender,
    }: {
      sender: 'client1' | 'client2' | 'me';
    }) => {
      if (sender === 'me') {
        return (
          <Avatar className="w-8 h-8 bg-background border border-border flex items-center justify-center">
            <div className="w-5 h-5 flex items-center justify-center">
              {LogoSvg}
            </div>
          </Avatar>
        );
      }
      return (
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-muted">
          <PersonIcon className="h-5 w-5 text-muted-foreground" />
        </div>
      );
    };
    AvatarRenderer.displayName = 'AvatarRenderer';
    return AvatarRenderer;
  }, []);

  const MessageGroupComponent = useMemo(() => {
    const MessageGroup = ({ messages }: { messages: Message[] }) => (
      <div className="space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={cn(
              'flex items-start gap-2',
              message.sender === 'me' ? 'flex-row-reverse' : 'flex-row',
              'relative pt-3',
            )}
          >
            <div className="absolute top-0">
              <RenderAvatarComponent sender={message.sender} />
            </div>
            <div className="min-w-8" />
            <div
              className={cn(
                'max-w-[70%] rounded-2xl px-4 py-2',
                message.sender === 'me'
                  ? 'bg-primary text-primary-foreground rounded-tr-lg'
                  : 'bg-muted rounded-tl-lg',
              )}
            >
              <p className="text-sm">{message.content}</p>
              <span className="text-xs opacity-50">{message.timestamp}</span>
            </div>
          </div>
        ))}
      </div>
    );
    MessageGroup.displayName = 'MessageGroup';
    return MessageGroup;
  }, [RenderAvatarComponent]);

  const conversationClient1 = useMemo<Message[]>(
    () => [
      {
        id: 1,
        content:
          'Bonjour, je souhaite créer un site web. Pouvez-vous m’aider ?',
        sender: 'client1',
        timestamp: '10:00',
      },
      {
        id: 2,
        content: 'Bonjour, bien sûr ! Quel est votre secteur d’activité ?',
        sender: 'me',
        timestamp: '10:01',
      },
      {
        id: 3,
        content:
          'Je suis coach en développement personnel. Je veux un site pour me présenter.',
        sender: 'client1',
        timestamp: '10:02',
      },
      {
        id: 4,
        content:
          'Parfait ! Nous livrons des sites rapides, modernes, optimisés SEO et adaptés à tous les écrans.',
        sender: 'me',
        timestamp: '10:03',
      },
      {
        id: 5,
        content: 'J’aimerais un design épuré et un formulaire de contact.',
        sender: 'client1',
        timestamp: '10:04',
      },
      {
        id: 6,
        content:
          'Nous vous livrons un site élégant, rapide et facile à trouver sur Google, avec un formulaire de contact intégré.',
        sender: 'me',
        timestamp: '10:05',
      },
      {
        id: 7,
        content: 'Quel est le délai pour la livraison ?',
        sender: 'client1',
        timestamp: '10:06',
      },
      {
        id: 8,
        content:
          'Site prêt en 2 semaines, efficace et sans compromis sur la qualité.',
        sender: 'me',
        timestamp: '10:07',
      },
      {
        id: 9,
        content: 'Super ! Quel est le budget approximatif ?',
        sender: 'client1',
        timestamp: '10:08',
      },
      {
        id: 10,
        content:
          'Je vous propose un devis personnalisé adapté à vos besoins. On peut se rencontrer pour en discuter ?',
        sender: 'me',
        timestamp: '10:09',
      },
      {
        id: 11,
        content: 'Oui, volontiers. Je suis disponible la semaine prochaine.',
        sender: 'client1',
        timestamp: '10:10',
      },
      {
        id: 12,
        content:
          'Parfait ! Je vous envoie un lien pour prendre rendez-vous. À bientôt !',
        sender: 'me',
        timestamp: '10:11',
      },
    ],
    [],
  );

  const conversationClient2 = useMemo<Message[]>(
    () => [
      {
        id: 5,
        content:
          'Bonjour, je suis commercial dans une entreprise de services. Nous cherchons à créer un site vitrine.',
        sender: 'client2',
        timestamp: '14:00',
      },
      {
        id: 6,
        content: 'Bonjour ! Quel type de services proposez-vous ?',
        sender: 'me',
        timestamp: '14:01',
      },
      {
        id: 7,
        content:
          'Nous proposons du conseil aux entreprises et avons besoin d’un site professionnel.',
        sender: 'client2',
        timestamp: '14:02',
      },
      {
        id: 8,
        content:
          'Nous créons des sites modernes, optimisés SEO, responsive et prêts à capter l’attention de vos clients.',
        sender: 'me',
        timestamp: '14:03',
      },
      {
        id: 9,
        content: 'Offrez-vous un suivi après la création ?',
        sender: 'client2',
        timestamp: '14:04',
      },
      {
        id: 10,
        content:
          'Oui, nous assurons un suivi pour maximiser la performance et la visibilité de votre site.',
        sender: 'me',
        timestamp: '14:05',
      },
      {
        id: 11,
        content: 'Quel est le délai pour la livraison ?',
        sender: 'client2',
        timestamp: '14:06',
      },
      {
        id: 12,
        content: 'Site livré en 2 semaines, rapide, optimisé et efficace.',
        sender: 'me',
        timestamp: '14:07',
      },
      {
        id: 13,
        content: 'Parfait, je vais en discuter avec mon équipe.',
        sender: 'client2',
        timestamp: '14:08',
      },
    ],
    [],
  );

  return (
    <div className={cn('w-full mx-auto overflow-hidden', className)}>
      <div className="h-[400px]">
        <Marquee vertical className="[--duration:50s]" pauseOnHover>
          <div className="py-4">
            <p className="text-xs text-center text-muted-foreground mb-2">
              Nouveaux messages
            </p>
            <Separator className="bg-border/50" />
          </div>
          <MessageGroupComponent messages={conversationClient1} />

          <div className="py-4">
            <p className="text-xs text-center text-muted-foreground mb-2">
              Nouveaux messages
            </p>
            <Separator className="bg-border/50" />
          </div>

          <MessageGroupComponent messages={conversationClient2} />
        </Marquee>
      </div>
    </div>
  );
};

export default ChatSimulation;

const LogoSvg = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 404 340"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="dark:fill-white"
  >
    <path
      d="M0 160.436C0 152.151 6.71573 145.436 15 145.436H364.743C373.028 145.436 379.743 152.151 379.743 160.436V170.834C379.743 179.118 373.028 185.834 364.743 185.834H15C6.71573 185.834 0 179.118 0 170.834V160.436Z"
      fill="url(#paint0_linear_62_12)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M399.361 69.2544L375.914 12.8544C372.672 5.05618 365.234 -1.69383e-06 357.004 -2.05358e-06C348.774 -2.41332e-06 341.336 5.05618 338.094 12.8544L276.374 161.318C274.156 166.653 274.156 172.692 276.374 178.027L338.094 326.491C341.336 334.289 348.774 339.345 357.004 339.345C365.234 339.345 372.672 334.289 375.914 326.491L403.984 258.97L379.073 211.211L357.004 264.295L317.667 169.673L357.004 75.0502L375.473 119.475L375.618 118.693L399.361 69.2544Z"
      className="dark:fill-white fill-black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.625 64.1669L244.12 12.8544C240.699 5.05617 232.852 -1.47624e-06 224.17 -1.85577e-06C215.487 -2.23529e-06 207.64 5.05617 204.22 12.8544L139.105 161.318C136.765 166.653 136.765 172.692 139.105 178.027L204.22 326.491C207.64 334.289 215.487 339.345 224.17 339.345C232.852 339.345 240.699 334.289 244.12 326.491L266.625 275.178L243.011 221.337L224.17 264.295L182.669 169.673L224.17 75.0502L243.011 118.008L266.625 64.1669Z"
      className="dark:fill-white/50 fill-black/50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.289 64.1669L108.191 12.8544C104.984 5.05617 97.6278 -1.38398e-06 89.4879 -1.73978e-06C81.3481 -2.09559e-06 73.9914 5.05617 70.7849 12.8544L9.74008 161.318C7.5464 166.653 7.5464 172.692 9.74008 178.027L70.7849 326.491C73.9914 334.289 81.348 339.345 89.4879 339.345C97.6278 339.345 104.984 334.289 108.191 326.491L129.289 275.178L107.151 221.337L89.4879 264.295L50.5814 169.673L89.4879 75.0502L107.151 118.008L129.289 64.1669Z"
      className="dark:fill-white/10 fill-black/10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_62_12"
        x1="0"
        y1="165.635"
        x2="379.743"
        y2="165.635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7C3AED" stopOpacity="0" />
        <stop offset="1" stopColor="#7C3AED" />
      </linearGradient>
    </defs>
  </svg>
);
