'use client';

import { cn } from '@/lib/utils';
import { AnimatedList } from '@/components/ui/magicui/animated-list';
import { useEffect, useState, useMemo, memo } from 'react';
import { ServiceSkeleton } from './ServiceSkeleton';

interface Item {
  name: string;
  description: string;
  icon: string;
  color: string;
  time?: string;
}

const Service = memo(({ name, description, icon, color, time }: Item) => {
  const figureStyles = cn(
    'relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-xl p-2 sm:p-4',
    'transition-all duration-300 ease-in-out',
    'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)] hover:bg-neutral-100',
    'transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset] hover:dark:bg-neutral-950',
  );

  return (
    <figure className={figureStyles}>
      <div className="flex flex-row items-center gap-2 sm:gap-3">
        <div
          style={{ backgroundColor: color }}
          className="relative h-8 w-8 shrink-0 items-center justify-center rounded-lg opacity-80 sm:h-12 sm:w-12 sm:rounded-xl"
        >
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-base sm:text-lg">
            {icon}
          </span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center gap-1.5">
            <span className="text-xs font-medium dark:text-white sm:text-base md:text-lg">
              {name}
            </span>
            {time && (
              <span className="text-[10px] text-gray-500 sm:text-xs">
                {time}
              </span>
            )}
          </figcaption>
          <p className="text-xs font-normal dark:text-white/60 sm:text-sm">
            {description}
          </p>
        </div>
      </div>
    </figure>
  );
});

Service.displayName = 'Service';

export function ServicesGrid({ className }: { className?: string }) {
  const [services, setServices] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const data = await import('@/public/data/services.json');
        const repeatedServices = Array.from(
          { length: 3 },
          () => data.services,
        ).flat();
        setServices(repeatedServices);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des services:', error);
        setIsLoading(false);
      }
    };

    loadServices();
  }, []);

  const containerStyles = useMemo(
    () =>
      cn(
        'relative flex h-[400px] sm:h-[500px] w-full flex-col overflow-hidden rounded-lg border p-3 sm:p-6 md:shadow-xl',
        className,
      ),
    [className],
  );

  if (isLoading) {
    return (
      <div className={containerStyles}>
        <div className="flex flex-col gap-4">
          {Array.from({ length: 5 }).map((_, idx) => (
            <ServiceSkeleton key={`skeleton-${idx}`} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={containerStyles}>
      <AnimatedList delay={2000}>
        {services.map((item, idx) => (
          <Service {...item} key={idx} />
        ))}
      </AnimatedList>
    </div>
  );
}
